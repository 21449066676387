import { render, staticRenderFns } from "./buy.vue?vue&type=template&id=36c5605b&scoped=true&"
import script from "./buy.vue?vue&type=script&lang=js&"
export * from "./buy.vue?vue&type=script&lang=js&"
import style0 from "./buy.vue?vue&type=style&index=0&id=36c5605b&scoped=true&lang=less&"
import style1 from "./buy.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c5605b",
  null
  
)

export default component.exports
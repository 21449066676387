<template>
    <transition name="fade">
        <div class="self-pick-all" v-if="isShow">
            <div class="self-pick-bg"></div>
            <div class="self-pick-main">
                <!-- <img src="@/assets/payment/close.png" alt="" class="self-close" @click="selfClose"> -->
                <div class="self-title">Complete your contact information</div>
                <div class="self-address">
                    Pick up location：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
                </div>
                <div class="self-row">
                    <div class="self-item">
                        <p>First name</p>
                        <input type="text" placeholder="" v-model="pickUpInfo.surname">
                    </div>
                    <div class="self-item">
                        <p>Last name</p>
                        <input type="text" placeholder="" v-model="pickUpInfo.name">
                    </div>
                </div>
                <div class="self-row">
                    <div class="self-item">
                        <p>Phone</p>
                        <input type="text" placeholder="Please enter your phone number" maxlength="13" v-model="pickUpInfo.tel">
                    </div>
                </div>
                <div class="self-row">
                    <div class="self-item">
                        <p>Email</p>
                        <input type="text" placeholder="Please enter your email address" v-model="pickUpInfo.email">
                    </div>
                </div>
                <div class="submit-button" @click="submitSelfPick">
                    Confirm
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { add_user_pick, put_user_pick } from '@/api/zh/bargain.js'
export default {
    props: {
        pickUpInfo: {
            type: Object,
        }
    },
    data() {
        return {
            isShow: true,
        }
    },
    methods: {
        selfClose() {
            // 取消自选
            this.$emit('close', false)
            this.isShow = false
        },
        submitSelfPick() {
            if(this.pickUpInfo.surname == '') {
                this.$notify({ type: 'warning', message: 'Please enter your surname' })
                return false
            }
            if(this.pickUpInfo.name == '') {
                this.$notify({ type: 'warning', message: 'Please enter your name' })
                return false
            }
            if(this.pickUpInfo.tel == '' || this.pickUpInfo.tel.length < 10) {
                this.$notify({ type: 'warning', message: 'Please input your mobile number correctly' })
                return false
            }
            /* eslint-disable */
            let patt = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
            if(this.pickUpInfo.email == '' || !patt.test(this.pickUpInfo.email)) {
                this.$notify({ type: 'warning', message: 'Please input your email correctly' })
                return false
            }
            let params = {
                surname: this.pickUpInfo.surname,
                name: this.pickUpInfo.name,
                phone: this.pickUpInfo.tel,
                email: this.pickUpInfo.email
            }
            if(this.pickUpInfo.id == '') {
                add_user_pick(params).then(res => {
                    if(res) {
                        this.$emit('submit', res.data.id)
                    }
                })
            } else {
                put_user_pick(this.pickUpInfo.id, params).then(res => {
                    if(res) {
                        this.$emit('submit', res.data.id)
                    }
                })
            }
            this.isShow = false
        }
    },
}
</script>

<style scoped lang="less">
.self-pick-all{position: fixed;width:100%;height:100%;z-index: 99;
    .self-pick-bg{width: 100%;height:100%;background-color: #333;opacity: .3;}
    .self-pick-main{position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);border-radius: 20px;background-color: #fff;z-index: 9;
    width: 311px;height: 457px;padding:0 20px;box-sizing: border-box;
        .self-close{position: absolute;top:15px;right:15px;width:20px;height:20px}
        .self-title{width:100%;height:60px;border-bottom:1px solid #f1f1f1;color:#333;text-align: center;line-height: 60px;font-size: 16px;}
        .self-row{width:100%;display: flex;margin-top:12px;
            .self-item{flex:1;
                &:nth-of-type(2){margin-left:10px}
                p{font-size: 14px;color:#333;line-height: 35px;
                    &::before{content: '';width: 2px;height: 12px;display: inline-block;background-color: #2D77E0;margin-right:5px;vertical-align: -2px;}
                }
                input{width:100%;height:40px;outline: none;border:1px solid #3284FA;box-sizing: border-box;border-radius: 5px;padding:0 10px;font-size: 14px;}
            }
        }
        .submit-button{width:192px;height:40px;background: linear-gradient(90deg, #2D77E0 3.17%, #245FB3 100%);border-radius: 21px;
        text-align: center;line-height: 40px;color:#fff;margin:24px auto 0 auto;font-size: 16px;}
    }
    .self-address{padding:10px 0 0 0;font-size: 14px;}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>